/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    hr: "hr",
    a: "a",
    div: "div",
    code: "code",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "Hadamard gate"), " is a gate in quantum computing that turns a state of $|0\\rangle$ or $|1\\rangle$ into an equal superposition of $|0\\rangle$ and $|1\\rangle.$"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Learn about ", React.createElement(_components.a, {
    href: "/codebook/02-single-qubit-gates/01-x-and-h/"
  }, "Hadamard gates in the PennyLane Codebook"), "."), "\n", React.createElement(_components.p, null, "In a circuit diagram, a Hadamard gate is represented by a square with the letter ‘H’ in it, as shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 32.278481012658226%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABJ0AAASdAHeZh94AAAAbElEQVQY05WRUQoAIQhEu//pOka/fWSUlMvItki4QcJgzIg8Kchbc86v77LlZXYm7MNejTF+s90PHiEzS+9de2tNcs6SUtL3yqAjoQ1rrVJKESJSYVmMUf3lQSC/PhkeyEC0Flyf7H3IKbeEDyVJ2wnlN3FEAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"hadamard-gate-symbol\"\n        title=\"hadamard-gate-symbol\"\n        src=\"/static/20c9f3022898d5b867e7e4295c6f92d3/f058b/hadamard-gate-symbol.png\"\n        srcset=\"/static/20c9f3022898d5b867e7e4295c6f92d3/c26ae/hadamard-gate-symbol.png 158w,\n/static/20c9f3022898d5b867e7e4295c6f92d3/6bdcf/hadamard-gate-symbol.png 315w,\n/static/20c9f3022898d5b867e7e4295c6f92d3/f058b/hadamard-gate-symbol.png 630w,\n/static/20c9f3022898d5b867e7e4295c6f92d3/13e20/hadamard-gate-symbol.png 741w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "A Hadamard gate can be represented in matrix form as"), "\n", React.createElement(_components.p, null, "$$\nH\\equiv\\frac{1}{\\sqrt{2}}\\left[\\begin{matrix}1& 1 \\\\ 1& -1\\end{matrix}\\right].\n$$"), "\n", React.createElement(_components.p, null, "If the input state is $|0\\rangle$, the Hadamard gate turns it into $(|0\\rangle + |1\\rangle) / \\sqrt2$. If the input state is $|1\\rangle$, the Hadamard gate turns it into a state of $(|0\\rangle - |1\\rangle) / \\sqrt2$."), "\n", React.createElement(_components.p, null, "The square of the Hadamard gate is just the identity gate: $H^{2} = I$. Thus, applying the Hadamard gate to the same qubit twice in a row has no effect on it."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.Hadamard.html"
  }, "Hadamard gate in PennyLane"), " is available as ", React.createElement(_components.code, null, "qml.Hadamard"), ". This gate is called ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/qiskit.circuit.library.HGate"
  }, React.createElement(_components.code, null, "HGate"), " in Qiskit"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Fun fact"), ": The Hadamard gate is named after French mathematician Jacques Hadamard. It is one of the most important gates, and is used in a wide variety of quantum algorithms."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
